<script lang="ts" setup>
export type SelectVariant = 'primary' | 'secondary' | 'button' | 'outline'
export type SelectSizes = 'sm' | 'md' | 'lg' | 'xl'
export interface SelectProps {
  name?: string
  label?: string
  isSearchable?: boolean
  options: any[]
  variant?: SelectVariant
  size?: SelectSizes
  isDisabled?: boolean
  placeholder?: string
  modelValue?: any
  error?: string
  isRequired?: boolean
  optionAttribute?: string
  valueAttribute?: string
  emptyText?: string
}

const { $ts } = useI18n()

const props = withDefaults(defineProps<SelectProps>(), {
  variant: 'primary',
  size: 'lg',
  isSearchable: false,
  isDisabled: false,
  placeholder: 'components.select.placeholder',
})
// TodO: add validation for options

const emit = defineEmits(['update:modelValue', 'blur'])

if (!props.name)
  throw createError('name is required')
if (!props.options)
  throw createError('options are required')

const modelValue = useVModel(props, 'modelValue', emit)
const fontColor = computed(() => modelValue.value ? 'text-neutral-900!' : 'text-neutral-500!')
const colorStyles: { [key in SelectVariant]: string } = {
  primary: 'bg-neutral-50!  dark:border-1! border-1 border-neutral-200',
  secondary: 'text-neutral-600! bg-neutral-300!',
  button: 'text-neutral-900 font-500 light:bg-primary-500 hover:bg-primary-600 dark:bg-primary-300  dark:hover:bg-primary-200 dark:text-neutral-100',
  outline: 'fbutton-outline fubex-rounded-md! [&>div>div>ul]:border-neutral-200! [&>div>div>ul]:dark:border-neutral-400! [&>div>button]:cursor-pointer',
}
const baseStyle = 'ring-0 focus:fubex-focus-ring font-400 cursor-pointer paragraph-md! border-neutral-200  border-1! fubex-rounded-lg! px-4px! py-4px!'

const totalStyle = computed(() => `
${baseStyle}
  ${colorStyles[props.variant]}
  ${fontColor.value}
`)
</script>

<template>
  <div v-auto-animate flex flex-col gap-6px w-full>
    <label v-if="label" paragraph-md font-600>
      {{ label }}
      <span text-error-default>{{ isRequired ? '*' : '' }}</span>
    </label>
    <ClientOnly>
      <USelectMenu v-model="modelValue" :name="name" :searchable="isSearchable" :placeholder="String($t(`${placeholder}`))" :disabled="isDisabled" :class="totalStyle" :size="size" :options="options" :option-attribute="optionAttribute" :value-attribute="valueAttribute" variant="none">
        <template #label>
          <slot name="value" :value="modelValue" />
        </template>
        <template #option="{ option: selectRow }">
          <div class="flex flex-row gap-2px">
            <slot name="option" :option="selectRow" />
            <span v-if="optionAttribute" class="truncate">{{ selectRow[optionAttribute] }}</span>
            <span v-else class="truncate">{{ selectRow.label || selectRow }}</span>
            <span class="mt-2px">
              <FTooltip v-if="selectRow.tooltipIcon" :text="selectRow.tooltipText || ''" :arrow="true" placement="right">
                <UnoIcon
                  :class="[
                    selectRow.tooltipIcon,
                    selectRow.tooltipColor,
                  ]" w-16px h-16px ml-1
                />
              </FTooltip>
            </span>
          </div>
        </template>
        <template #empty>
          {{ props.emptyText ?? $ts('components.dialogChangeOrderStatus.emptyText') }}
        </template>
      </USelectMenu>
    </ClientOnly>
    <div v-if="error" text-error-default paragraph-md>
      {{ error.includes('__key__') ? $t(error.replace('__key__', '')) : error }}
    </div>
  </div>
</template>
